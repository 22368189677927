import React, { useState, useEffect } from "react"
import sanityClient from "../client.js"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'



export default function Loader()
{
    return (
        <div className="loader">
            <h2>casi listo</h2>
        </div>
        )
}


    
